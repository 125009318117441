import { RouteRecordRaw, RouterView } from 'vue-router'

import { dayjs, DTF } from '@algorh/shared'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const trainerRouter: RouteRecordRaw[] = [
  {
    name: 'trainer-calendar',
    path: 'calendar',
    redirect: {
      name: 'trainer-my-planning',
    },
  },
  {
    name: 'trainer-my-planning',
    path: 'my-planning/:date?',
    meta: {
      redirectable: true,
      weight: 100,
      permissions: [UserPermissions.ACCESS_TRAINING],
      layout: AppLayout,
      breadcrumb: 'calendar.My planning',
    },
    component: () => import('./pages/my-planning/MyPlanning.vue'),
    beforeEnter: (to) => {
      if (to.params.date && dayjs(to.params.date as string, DTF.DATE).isValid()) {
        return true
      }
      return {
        name: 'trainer-my-planning',
        params: {
          // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
          date: dayjs().startOf('week').format(DTF.DATE),
        },
      }
    },
  },
  {
    path: 'trainer',
    name: 'trainer',
    component: RouterView,
    meta: {
      layout: AppLayout,
      permissions: [
        UserPermissions.ACCESS_TRAINING,
      ],
    },
    children: [
      {
        path: 'training-list',
        name: 'training-list',
        component: () =>
          import('./pages/training-list/TrainingList.vue'),
        meta: {
          breadcrumb: 'trainer.Past and planned internal tasks',
        },
        children: [
          {
            path: ':internalTaskInstanceId',
            name: 'training-detail',
            component: () =>
              import('@/sections/scheduling/pages/internal-tasks/InternalTaskInstance.vue'),
          },
        ],
      },
    ],
  },
]

export { trainerRouter }
